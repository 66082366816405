.dgf-list--news {
    .card--news {
        .card-header {
            padding-top: var(--bs-card-spacer-y);
            padding-bottom: 0;
            
            background-color: transparent;
            font-weight: normal!important;
            
            + .card-body {
                padding-top: calc(var(--bs-card-spacer-y) * .25);
            }
        }

        .card-title {

            &:not(:has(> a)) {
                @include truncate-text($rows: 3);
            }

            > a {
                @include truncate-text($rows: 3);
            }
        }
        
        .card-text__info {
            @include truncate-text($rows: 3);
        }
    }

    &.dgf-list--list {
        .card--news {
            .card-title {
                &:not(:has(> a)) {
                    @include truncate-text($rows: 2);
                }
                > a {
                    @include truncate-text($rows: 2);
                }
            }

            .card-text {
                display: none;
            }
        }

        .dgf-list__body > .dgf-row > .col:first-of-type > .card{
            border-top-width: 1px;
        }
        .dgf-list__body > .dgf-row > .col:last-of-type > .card{
            border-bottom-width: 1px;
        }
    }

}

.article-list{
    &__footer{
        .splide__arrow{
            &--prev{
                img{
                    transform: rotate(180deg);
                }
            }
        }
    }
}